import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import useSelector from '../redux/typedHooks';
import { ConsentObject } from '../../../types/consentType';

interface TokenType {
  token?: string | undefined;
  isNewCustomer?: boolean;
}

interface ReturnType {
  updateConsents: () => void;
  consents: ConsentObject[] | undefined;
  isLoading: boolean;
  error: boolean;
}

export const useFetchConsents = ({ token, isNewCustomer = false }: TokenType): ReturnType => {
  const [consents, setConsents] = useState<ConsentObject[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const { apimBaseUrl, apimContentHub, apimConsentsApi } = useSelector((state) => state.application);

  const getConsents = useCallback(async () => {
    const customer = isNewCustomer ? 'new-customer' : 'customer';
    if (!isNewCustomer && !token) return;
    setIsLoading(true);
    try {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const res = await axios.get<{ consents: ConsentObject[] }>(
        `${apimBaseUrl}/${apimConsentsApi}/v2/${customer}/consents`,
        {
          headers,
        },
      );
      setIsLoading(false);
      let count = -1;
      const counter = () => {
        count += 1;
        return count;
      };
      setConsents(
        res.data.consents.map((consent) => ({
          ...consent,
          indexId: counter(),
          children: consent.children?.map((child) => ({ ...child, indexId: counter() })),
        })),
      );
      setError(false);
    } catch (e) {
      setIsLoading(false);
      setError(true);
    }
  }, [token, apimBaseUrl, apimConsentsApi, apimContentHub, isNewCustomer]);

  useEffect(() => {
    getConsents();
  }, [getConsents]);

  return { updateConsents: getConsents, consents, isLoading, error };
};
