import { Controller, FieldValues, UseControllerProps, UseControllerReturn } from 'react-hook-form';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { Message } from '@naf/input';
import RadioButton from '../RadioButton';

export const ConsentSelect = <T extends FieldValues>({
  control,
  defaultValue,
  errorMessage,
  name,
  disabled = false,
  setNafConsent,
  onSelectNo,
  onSelectYes,
}: UseControllerProps<T> & {
  errorMessage?: string;
  disabled?: boolean;
  setNafConsent?: Dispatch<SetStateAction<boolean>>;
  onSelectNo?: () => void;
  onSelectYes?: () => void;
}) => (
  <FieldWrapper>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }: UseControllerReturn<T>) => (
        <RadioButtonGroup>
          <StyledRadioButton
            onChange={() => {
              onChange({ ...value, consented: true, undetermined: false });
              if (setNafConsent) {
                setNafConsent(true);
              }
              if (onSelectYes) {
                onSelectYes();
              }
            }}
            variant={typeof errorMessage !== 'undefined' ? 'error' : 'standard'}
            checked={value.consented && !value.undetermined}
            outline={false}
            label="Ja"
            name={name}
            isDisabled={disabled}
          />
          <StyledRadioButton
            onChange={() => {
              onChange({ ...value, consented: false, undetermined: false });
              if (setNafConsent) {
                setNafConsent(false);
              }
              if (onSelectNo) {
                onSelectNo();
              }
            }}
            variant={typeof errorMessage !== 'undefined' ? 'error' : 'standard'}
            checked={value.consented === false && !value.undetermined}
            outline={false}
            label="Nei"
            name={name}
            isDisabled={disabled}
          />
        </RadioButtonGroup>
      )}
    />
    {errorMessage && <Message error>{errorMessage}</Message>}
  </FieldWrapper>
);

const FieldWrapper = styled.div`
  margin-top: ${spacing.space24};
  @media (min-width: ${breakpoints.m}) {
    width: 544px;
  }
  width: 100%;
`;

const RadioButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const StyledRadioButton = styled(RadioButton)`
  padding: 0;
  @media (max-width: ${breakpoints.s}) {
    width: fit-content;
    margin-right: ${spacing.space32};
  }
`;
